
import { SenecaResponse, Item, User } from "atfcore-commonclasses";


export interface IStateParamsControllerScope extends angular.ui.IStateParamsService {
    searchId: number;
}

export interface IItemListAdminDirectiveControllerScope {
    searchId: number;
    Homectrl: IItemListAdminDirectiveControllerScope;
    globalApplicationData: any;
    bgAcademyApplicationData: any;
    chartOptions: any;
    isDoingSomething: boolean;
    isCardResultView: boolean;
    genericBlockUI: any;
    getItemList: Function;
    itemList: Array<Item>;
    addFoundedItems: Function;
    countItems: Function;
    countItemListPromise: any;
    totalItemsLoaded: number;
    infiniteScrollEnabled: boolean;
    isInfiniteScrollLoading: boolean;
    infiniteScrollLoadCount: number;
    goToEditItem: Function;
    goToPreviewItem: Function;
    getItemListPromise: any;
    isLibraryTabFilterToolbarExpanded: boolean;
    expandLibraryTabFilterToolbar: Function;
    tableOptions: any;
    haveSameId: Function;
    tabOneTitle: string;
    tabTwoTitle: string;
    inputPlaceholder: string;
    removeFilters: Function;
    hasTabsAtLeastOneFilter: Function;
    countTabFilters: Function;
    itemsSorting: Array<any>;
    competenceList: Array<any>;
    statusList: Array<any>;
    suggestedItemsFilters: any;
    suggestedItemsSorting: Array<any>;
    filters: any;
    tableItemsOptions: any;
    allObjectTypes: Array<string>;
    deleteItem: Function;
    deleteItemAdmin: Function;
    editMode: string | boolean;
    selectedItems: Array<Item>;
    selectionMode: string;
    itemSelected: (item: Item) => boolean;
    toggleSelectedItem: (item: Item) => void;
    showContainersTab: boolean;
    containersTabSelected: Function;
    objectsTabSelected: Function;
    goToInvitedListAdmin: Function;
    hidePreviewBtn: Function;
    withAllVisibilitiesLoaded: string | boolean;
    newSearch: boolean;
    saveNewSearch: Function;
    startNewPagination: Function;
}


class ItemListAdminDirectiveController {
    static $inject = ["$scope", "$state", "$rootScope", "$translate", "$stateParams", "$q", "blockUI", "toaster", "$mdDialog", "GlobalApplicationData", "BgacademyApplicationData", "$window", "ItemService", "$timeout", "moment", "$sessionStorage", "SessionStorageService"];

    constructor(protected $scope: IItemListAdminDirectiveControllerScope,
        protected $state: angular.ui.IStateService,
        protected $rootScope: angular.IRootScopeService,
        protected $translate: angular.translate.ITranslateService,
        protected $stateParams: IStateParamsControllerScope,
        protected $q: angular.IQService,
        protected blockUI: any,
        protected toaster: any,
        protected $mdDialog: ng.material.IDialogService,
        protected GlobalApplicationData: any,
        protected BgacademyApplicationData: any,
        protected $window: ng.IWindowService,
        protected ItemService: any,
        protected $timeout: ng.ITimeoutService,
        protected moment: any,
        protected $sessionStorage: any,
        protected SessionStorageService: any
    ) {
        // Collegamento all'oggetto principale
        $scope.globalApplicationData = GlobalApplicationData;
        $scope.bgAcademyApplicationData = BgacademyApplicationData;

        // Espande la barra dei filtri
        $scope.isLibraryTabFilterToolbarExpanded = false;
        $scope.expandLibraryTabFilterToolbar = this.expandLibraryTabFilterToolbar;

        // Verifica se due oggetti hanno id uguali
        $scope.haveSameId = this.haveSameId;

        // Verifica se nascondere il pulsante per andare nella preview dell'item
        $scope.hidePreviewBtn = this.hidePreviewBtn;

        // Porta alla pagina di anteprima di un oggetto
        $scope.goToPreviewItem = this.goToPreviewItem;

        // Avvia una nuova ricerca
        $scope.saveNewSearch = this.saveNewSearch;

        $scope.newSearch = true;

        $scope.startNewPagination = this.startNewPagination;

        // Recupero il parametro dall'url che rappresenta il numero di ricerca in cui sono arrivato. Se non ce l'ho significa che sono alla prima ricerca
        $scope.searchId = this.$stateParams.searchId && this.$stateParams.searchId > 0 ? this.$stateParams.searchId : 0;

        // Titolo in traduzione delle tre tab
        $scope.tabOneTitle = $translate.instant("itemListAdmin.OBJECTS");
        $scope.tabTwoTitle = $translate.instant("itemListAdmin.CONTAINERS");

        // Traduzione del placeholder dell'input di ricerca 
        $scope.inputPlaceholder = $translate.instant("librarySearch.SEARCH_CONTENT");

        // Contiene tutte le tipolgie di oggetti
        $scope.allObjectTypes = $scope.allObjectTypes || [
            this.BgacademyApplicationData.constants.MAGAZINE,
            this.BgacademyApplicationData.constants.BOOK,
            this.BgacademyApplicationData.constants.LEARNING_PLAN,
            this.BgacademyApplicationData.constants.PROJECT,
            this.BgacademyApplicationData.constants.SECTION,
            this.BgacademyApplicationData.constants.DOCUMENT,
            this.BgacademyApplicationData.constants.PODCAST,
            this.BgacademyApplicationData.constants.GRAPH,
            this.BgacademyApplicationData.constants.EBOOK,
            this.BgacademyApplicationData.constants.SCORM,
            this.BgacademyApplicationData.constants.IMAGE,
            this.BgacademyApplicationData.constants.DVD,
            this.BgacademyApplicationData.constants.VIDEO
        ];

        // Switch modalità lista / cards degli oggetti
        $scope.isCardResultView = false;

        // Azzera tutti i filtri
        $scope.removeFilters = this.removeFilters;

        // Verifica che nelle tab ci sia almeno un filtro attivo
        $scope.hasTabsAtLeastOneFilter = this.hasTabsAtLeastOneFilter;
        // Conta il numero di filtri attivi 
        $scope.countTabFilters = this.countTabFilters;

        // Block-UI generico della pagina
        $scope.genericBlockUI = this.blockUI.instances.get('genericBlockUI');

        // Elenco di competenze disponibili
        $scope.competenceList = [
            { id: 1, name: $translate.instant("librarySearch.ALL") },
            { id: 2, name: $translate.instant("librarySearch.TECH_SKILL") },
            { id: 3, name: $translate.instant("librarySearch.SOFT_SKILL") }
        ];

        // Elenco di ordinamenti disponibili per gli elementi suggeriti
        $scope.suggestedItemsSorting = [
            { id: 1, label: "T", name: $translate.instant("librarySearch.sorting.TITLE"), type: 'title' },
            { id: 2, label: "D", name: $translate.instant("librarySearch.sorting.DATE"), type: 'creationDate' }
        ];

        // Elenco di status disponibili
        $scope.statusList = [
            { id: 1, name: $translate.instant("librarySearch.ALL") },
            { id: 2, name: $translate.instant("librarySearch.VIEWED") },
            { id: 3, name: $translate.instant("librarySearch.NOT_VIEWED") }
        ];

        // Elenco di ordinamenti disponibili per i risultati della ricerca
        $scope.itemsSorting = [
            { id: 1, label: "S", name: $translate.instant("librarySearch.sorting.SCORE") },
            { id: 2, label: "T", name: $translate.instant("librarySearch.sorting.TITLE"), type: 'title' },
            { id: 3, label: "D", name: $translate.instant("librarySearch.sorting.DATE"), type: 'creationDate' }
        ];

        // Ordinamento degli elementi suggeriti
        $scope.suggestedItemsFilters = {
            sorting: $scope.suggestedItemsSorting[1]
        }

        // Controlla se c'è qualche azione in corso e, in tal caso, disabilito i pulsanti
        $scope.isDoingSomething = false;

        // Contenitore dei risultati della ricerca degli item
        $scope.itemList = [];

        // Avvia la ricerca degli elementi in Library
        $scope.getItemList = this.getItemList;

        $scope.containersTabSelected = this.containersTabSelected;
        $scope.objectsTabSelected = this.objectsTabSelected;

        // Aggiunge gli elementi trovati dopo la ricerca
        $scope.addFoundedItems = this.addFoundedItems;

        $scope.countItems = this.countItems;
        $scope.totalItemsLoaded = 0;

        // Rappresenta tutti i filtri
        $scope.filters = {
            searchedText: '', // ricerca testuale nella tab 'Library'
            selectedTechSkillTags: [], // chips selezionate riguardanti le competenze tech nella tab 'Library'
            selectedSoftSkillTags: [], // chips selezionate riguardanti le competenze soft nella tab 'Library'
            selectedTopicTags: [], // chips selezionate riguardanti gli argomenti nella tab 'Library'
            selectedTypeTags: [], // chips selezionate rigaurdanti il tipo di oggetto nella tab 'Library'
            competence: $scope.competenceList[0], // competenza scelta (tech/soft) nella tab 'Library'
            status: $scope.statusList[0], // status scelto (visti, non visti, tutti) nella tab 'Library'
            sorting: $scope.itemsSorting[0], // Ordinamento scelto per i risultati della ricerca, di default è l'ordinamento per pertinenza
            tableFilter: {
                page: 1
            },
            infiniteScrollItemsStartOffSet: 0,
            totalItemsCount: 0,
            openedTab: 0
        };

        // Configurazione della tabella contenente la lista degli items
        $scope.tableItemsOptions = {
            rowSelection: false,
            multiSelect: false,
            autoSelect: false,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true
        };

        // Configurazione chart con la percentuale del completamento piano
        $scope.chartOptions = {
            animate: {
                duration: 800,
                enabled: true
            },
            barColor: '#1abc9c',
            scaleColor: '',
            trackColor: '#fff',
            lineWidth: 2,
            lineCap: 'butt',
            size: 75
        }

        $scope.tableOptions = {
            rowSelection: false,
            multiSelect: false,
            autoSelect: false,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true
        };

        // Variabili che bloccano l'infinite scroll finché sta caricando o la pagina non è pronta
        $scope.infiniteScrollEnabled = false;
        $scope.isInfiniteScrollLoading = false;

        // Dato che l'infinite scroll appende i dati, devo partire con una lista esistente vuota
        $scope.infiniteScrollLoadCount = 15;

        // Porta alla pagina di modifica di un corso
        $scope.goToEditItem = this.goToEditItem;

        // Apre una modale che chiede conferma sulla rimozione dell'Item
        $scope.deleteItemAdmin = this.deleteItemAdmin;

        // Avvio subito la ricerca degli item
        //this.$scope.getItemList();

        $scope.selectedItems = $scope.selectedItems || [];

        $scope.itemSelected = (item: Item) => {
            return $scope.selectedItems.filter((i) => i.itemId == item.itemId).length > 0;
        }

        $scope.toggleSelectedItem = (item: Item) => {
            if ($scope.selectionMode && $scope.selectionMode == 'MULTIPLE') {
                if ($scope.itemSelected(item)) {
                    $scope.selectedItems = $scope.selectedItems.filter((i) => i.itemId != item.itemId);
                } else {
                    $scope.selectedItems.push(item);
                }
            } else {
                $scope.selectedItems = [];
                $scope.selectedItems.push(item);
            }
        }

        $scope.editMode = $scope.editMode === true || $scope.editMode === "true";

        $scope.goToInvitedListAdmin = (itemId: string) => {
            $state.go("app.bgacademyApp.itemInvitedListAdmin", { itemId: itemId });
        }

        $scope.withAllVisibilitiesLoaded = $scope.withAllVisibilitiesLoaded === true || $scope.withAllVisibilitiesLoaded === "true";
        // Recupero il parametro dall'url che rappresenta il numero di ricerca in cui sono arrivato. Se non ce l'ho significa che sono alla prima ricerca
        $scope.searchId = this.$stateParams.searchId && this.$stateParams.searchId > 0 ? this.$stateParams.searchId : 0;
        // Incrementa il numero della ricerca, salva la ricerca nel Session Storage eliminando, eventualmente, tutto ciò che c'è dopo l'attuale indice nel Session Storage
        $scope.saveNewSearch = this.saveNewSearch;
        // Se non sono nella prima pagina di ricerca, significa che devo chiamare il server per recuperare i dati della ricerca dello state precedente
        if ($scope.searchId != 0) {
            // Sto iniziando un nuovo giro di ricerche (per l'infinite scroll)
            this.$scope.newSearch = true;
            this.$scope.getItemList(null, null);
        } else {
            // Altrimenti avvio le ricerche di default, recuperando gli elementi suggeriti e quelli che fanno parte delle mie attività
            this.$scope.saveNewSearch();
        }
    }

    // Verifica se nascondere il pulsante per andare nella preview di un item
    public hidePreviewBtn = () => {
        let isHide = false;
        if (this.$state.includes('app.bgacademyApp.selectItemsContainer')) {
            isHide = true;
        }

        return isHide;
    }

    // Cancella un Item
    public deleteItemAdmin = (event: any, item: any) => {
        this.$mdDialog.show({
            controller: this.DeleteItemController,
            templateUrl: 'deleteItemAdmin.html',
            parent: angular.element(document.body),
            targetEvent: event,
            clickOutsideToClose: true
        })
            .then(() => {
                // Blocco la schermata
                this.blockUI.start();

                // Rimuovo l'oggetto
                this.ItemService.deleteItem.query({
                    itemId: item.itemId,
                    removeAtomicItemsToo: false
                }).$promise
                    .then((data: SenecaResponse<Item>) => {
                        // Se c'è segnalo l'errore
                        if (data.error) {
                            this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                        } else {
                            this.toaster.pop("success", this.$translate.instant('generic.OPERATION_PERFORMED'), this.$translate.instant('editItemAdmin.DELETED'));

                            // Svuoto la lista
                            this.$scope.itemList.length = 0;
                            this.$scope.filters.totalItemsCount = 0;
                            this.$scope.filters.infiniteScrollItemsStartOffSet = 0;
                            this.$scope.totalItemsLoaded = 0;
                            this.$scope.filters.tableFilter.page = 1;
                            this.$scope.removeFilters();


                            // E la aggiorno
                            this.$scope.getItemList();
                        }
                        // Sblocco la schermata
                        this.blockUI.stop();
                    })
                    .catch((error: Error) => {
                        // Sblocco la schermata
                        this.blockUI.stop();
                        let errors: Array<any> = [];
                        errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                        this.$rootScope.$emit("showApplicationModalErrors", errors);
                    });
            }, () => {
                // In caso di dismiss non faccio niente
            });
    }

    // Controller per la modale che chiede la conferma per l'eliminazione dell'item
    DeleteItemController = ($scope: any, $mdDialog: any) => {
        // Chiude la modale annullando l'operazione
        $scope.cancel = () => {
            $mdDialog.cancel();
        };

        // Confermo l'operazione e rimuovo l'item
        $scope.confirm = () => {
            $mdDialog.hide();
        };
    }

    // Espande la barra dei filtri
    private expandLibraryTabFilterToolbar = () => {
        this.$scope.isLibraryTabFilterToolbarExpanded = !this.$scope.isLibraryTabFilterToolbarExpanded;
    }

    // Azzera i filtri
    private removeFilters = (updateItemList = false) => {
        this.$scope.filters.searchedText = '';
        this.$scope.filters.selectedTechSkillTags.length = 0;
        this.$scope.filters.selectedSoftSkillTags.length = 0;
        this.$scope.filters.selectedTopicTags.length = 0;
        this.$scope.filters.selectedTypeTags.length = 0;
        this.$scope.filters.competence = this.$scope.competenceList[0];
        this.$scope.filters.status = this.$scope.statusList[0];
        if (updateItemList) {
            this.$scope.saveNewSearch(true, true, true);
        }
    }

    // Verifica che ci sia almeno un filtro attivo
    private hasTabsAtLeastOneFilter = () => {
        if (this.$scope.filters.selectedTechSkillTags.length > 0 || this.$scope.filters.selectedSoftSkillTags.length > 0 || this.$scope.filters.selectedTopicTags.length > 0 || this.$scope.filters.selectedTypeTags.length > 0 || !this.$scope.haveSameId(this.$scope.filters.competence, this.$scope.competenceList[0]) || !this.$scope.haveSameId(this.$scope.filters.status, this.$scope.statusList[0])) {
            return true;
        }
        return false;
    }

    // Conta il numero di filtri attivi
    private countTabFilters = () => {
        let count: number = null;
        count = + this.$scope.filters.selectedTechSkillTags.length + this.$scope.filters.selectedSoftSkillTags.length + this.$scope.filters.selectedTopicTags.length + this.$scope.filters.selectedTypeTags.length;
        if (!this.$scope.haveSameId(this.$scope.filters.status, this.$scope.statusList[0])) {
            count++;
        }
        if (!this.$scope.haveSameId(this.$scope.filters.competence, this.$scope.competenceList[0])) {
            count++;
        }
        return count;
    }

    // Porta alla pagina di modifica di un oggetto
    public goToEditItem = (item: any) => {
        if (this.$scope.filters.openedTab == 1) {
            this.$state.go('app.bgacademyApp.editContainerItemAdmin', { itemId: item.itemId });
        } else {
            this.$state.go('app.bgacademyApp.editItemAdmin', { itemId: item.itemId });
        }
    }

    // Porta alla pagina di anteprima di un oggetto
    public goToPreviewItem = (item: any) => {
        // Redirect in base al fatto che sia una playlist, un learning plan o un oggetto atomico
        if (item && item.typology === this.BgacademyApplicationData.constants.PROJECT) {
            this.$state.go('app.bgacademyApp.learningPlanDetailAdmin', { itemId: item.itemId });
        } else if (item && item.typology === this.BgacademyApplicationData.constants.LEARNING_PLAN) {
            this.$state.go('app.bgacademyApp.playlistDetailAdmin', { itemId: item.itemId });
        } else {
            this.$state.go('app.bgacademyApp.itemDetailAdmin', { itemId: item.itemId });
        }
    }

    // Effettua il conteggio degli item
    public countItems = (searchedText: string, status: string, techSkillsOnly: boolean, softSkillsOnly: boolean, techCompetences: Array<string>, softCompetences: Array<string>, argumentsTags: Array<string>, objectTypes: Array<string>, itemTypes: Array<string>) => {
        return this.$q((resolve: Function, reject: Function) => {
            // Se ho già recuperato il count, risolvo subito la promessa
            if (this.$scope.filters.totalItemsCount) {
                resolve();
            } else {
                // Eseguo la ricerca, eliminando le eventuali altre pendenti
                if (this.$scope.countItemListPromise) {
                    this.$scope.countItemListPromise.$cancelRequest();
                }
                this.$scope.countItemListPromise =
                    this.ItemService.countItems.query({
                        sorting: this.$scope.filters.sorting.label,
                        searchedText: searchedText,
                        status: status,
                        techSkillsOnly: techSkillsOnly,
                        softSkillsOnly: softSkillsOnly,
                        techCompetences: techCompetences,
                        softCompetences: softCompetences,
                        argumentsTags: argumentsTags,
                        objectTypes: objectTypes && objectTypes.length ? objectTypes : this.$scope.allObjectTypes,
                        itemTypes: itemTypes,
                        ignoreVisibility: true
                    });
                this.$scope.countItemListPromise.$promise
                    .then((data: SenecaResponse<any>) => {
                        if (data.error) {
                            // Dati non validi, quindi alzo l'errore
                            this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                            reject();
                        } else {
                            // Salvo il risultato della ricerca
                            this.$scope.filters.totalItemsCount = data.response;
                            resolve();
                        }
                        // Annullo la promessa
                        this.$scope.countItemListPromise = null;
                    })
                    .catch((error: any) => {
                        // Annullo la promessa
                        this.$scope.countItemListPromise = null;
                        reject();
                        // Non mostro la modale di errore se ho cancellato volutamente la richiesta                            
                        if (!error || error.config.timeout.$$state.status !== 1) {
                            let errors: Array<any> = [];
                            errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                            this.$rootScope.$emit("showApplicationModalErrors", errors);
                        }
                    });
            }
        })
    }

    // Evento di tab playlist/learning plan selezionato
    public containersTabSelected = () => {
        if (!this.$scope.newSearch) {
            this.$scope.filters.openedTab = 1;
            return this.$scope.saveNewSearch(true, true, true);
        }
    }

    // Evento di tab oggetti selezionato
    public objectsTabSelected = () => {
        if (!this.$scope.newSearch) {
            this.$scope.filters.openedTab = 0;
            return this.$scope.saveNewSearch(true, true, true);
        }
        // return this.getItemList(null, null, true);
    }

    // Incrementa il numero della ricerca, salva la ricerca nel Session Storage eliminando, eventualmente, tutto ciò che c'è dopo l'attuale indice nel Session Storage
    public saveNewSearch = (isFirstPage: boolean, fromSearchBar: boolean, fromSearchBarBtn: boolean, firstTab: boolean) => {
        // Poiché nella direttiva <search-bar> la funzione saveNewSearch viene avviata anche all'onSelect della tab (e poiché di default è selezionata la prima, tale metodo viene invocato automaticamente), devo bloccare subito l'operazione per evitare una duplice ricerca, visto che essa viene avviata dal componente md.data.table
        if (fromSearchBar && !fromSearchBarBtn && firstTab) {
            return;
        }

        if (fromSearchBar && fromSearchBarBtn) {
            this.$scope.itemList.length = 0;
            this.$scope.filters.totalItemsCount = 0;
            this.$scope.filters.infiniteScrollItemsStartOffSet = 0;
            this.$scope.totalItemsLoaded = 0;
            this.$scope.filters.tableFilter = {
                page: 1
            };
        }

        // Verifico quanti elementi ho nel Session Storage
        let count = this.SessionStorageService.count();
        // Se ne ho più di quanto indica il mio indice di ricerca significa che sono tornato indietro nell'history e che quindi devo cancellare tutto ciò che da qui in poi
        if (count > this.$scope.searchId) {
            this.SessionStorageService.clearFrom(this.$scope.searchId, count);
        }
        // Incremento il numero della ricerca
        this.$scope.searchId++;

        // Salvo nel Session Storage il nuovo set di filtri
        this.SessionStorageService.saveObject(this.$scope.searchId, this.$scope.filters);

    
        let backAction = this.$stateParams["backAction"];

        if(backAction == 'app.bgacademyApp.createContainerItemAdmin' || backAction == 'app.bgacademyApp.createSectionContainerItemAdmin'
        || backAction == 'app.bgacademyApp.editContainerItemAdmin' || backAction == 'app.bgacademyApp.editSectionContainerItemAdmin'){
            this.$state.go('app.bgacademyApp.selectItemsContainer', { searchId: this.$scope.searchId }, { reload: 'app.bgacademyApp.selectItemsContainer' });
        }
        else{

        // Il redirect dipende dalla pagina in cui arrivo (posso essere ad esempio in library utente, oppure nell'edit di una sezione)
        let redirectUrl = 'app.bgacademyApp.itemListAdmin';

        // E vado nel nuovo state
        this.$state.go(redirectUrl, { searchId: this.$scope.searchId }, { reload: redirectUrl });
    }
}

    // Avvia una ricerca per una nuova paginazione, salvando i filtri (così salvo anche la paginazione)
    public startNewPagination = (offset: any, resultLimit: any) => {
        if (!this.$scope.isDoingSomething && !this.$scope.newSearch) {
            this.SessionStorageService.saveObject(this.$scope.searchId, this.$scope.filters);
            this.$scope.getItemList(offset, resultLimit);
        }
    }

    // Recupera la lista degli item
    public getItemList = (offset: any, resultLimit: any, newSearch: boolean) => {
        // Avvio la ricerca solo se ho almeno un filtro, oppure un testo, oppure è la prima volta che entro in pagina (e dunque avvio la ricerca cercando per tutte le tipologie di oggetto)
        // if (this.$scope.hasTabsAtLeastOneFilter() || this.$scope.filters.searchedText || newSearch) {
        // Attivo il blockUI
        this.$scope.genericBlockUI.start();

        // Disabilito i pulsanti
        this.$scope.isDoingSomething = true;

        // Ricerca relativa allo state precedente 
        if (this.$scope.searchId != 0) {
            // Recupero dal Session Storage il set di filtri 
            let filters: any = this.SessionStorageService.getObject(this.$scope.searchId);

            if (filters) {
                // Aggiorno i filtri 
                this.$scope.filters = filters;
            }
        }

        // Se è una nuova ricerca, pulisco i dati
        if (this.$scope.newSearch && newSearch) {
            this.$scope.itemList.length = 0;
            this.$scope.filters.totalItemsCount = 0;
            this.$scope.filters.infiniteScrollItemsStartOffSet = 0;
            this.$scope.totalItemsLoaded = 0;
            this.$scope.filters.tableFilter = {
                page: 1
            };
            this.SessionStorageService.saveObject(this.$scope.searchId, this.$scope.filters);
        }

        // Preparo i dati calcolati da inviare al server
        let status: string = null, // status (tutti/visto/non visto)
            techSkillsOnly: boolean = null, // cerca fra le competenze tecniche
            softSkillsOnly: boolean = null, // cerca fra le competenze soft
            techCompetences: Array<string> = [], // Tag con competenze tecniche
            softCompetences: Array<string> = [], // Tag con competenze soft
            argumentsTags: Array<string> = [], // Tag con gli argomenti
            objectTypes: Array<string> = [], // Tag con i tipi di oggetto
            searchedText: string = '' // testo libero


        // Imposto il testo di ricerca libero recuperato
        searchedText = this.$scope.filters.searchedText;

        // Recupero la lista di tag con compotenze tecniche
        if (this.$scope.filters.selectedTechSkillTags) {
            for (let k = 0; k < this.$scope.filters.selectedTechSkillTags.length; k++) {
                techCompetences.push(this.$scope.filters.selectedTechSkillTags[k].tagId);
            }
        }

        // Recupero la lista di tag con compotenze soft
        if (this.$scope.filters.selectedSoftSkillTags) {
            for (let k = 0; k < this.$scope.filters.selectedSoftSkillTags.length; k++) {
                softCompetences.push(this.$scope.filters.selectedSoftSkillTags[k].tagId);
            }
        }

        // Recupero la lista di tag con gli argomenti
        if (this.$scope.filters.selectedTopicTags) {
            for (let k = 0; k < this.$scope.filters.selectedTopicTags.length; k++) {
                argumentsTags.push(this.$scope.filters.selectedTopicTags[k].tagId);
            }
        }

        // Recupero la lista di tag con le tipologie di oggetto
        if (this.$scope.filters.selectedTypeTags) {
            for (let k = 0; k < this.$scope.filters.selectedTypeTags.length; k++) {
                // Se è un e-learning, imposto il filtro scorm
                if (this.$scope.filters.selectedTypeTags[k].code == this.BgacademyApplicationData.constants.ELEARNING) {
                    objectTypes.push(this.BgacademyApplicationData.constants.SCORM);
                } else {
                    objectTypes.push(this.$scope.filters.selectedTypeTags[k].code);
                }
            }
        }

        // Calcolo lo stato in base alla scelta nella select
        if (this.$scope.haveSameId(this.$scope.filters.status, this.$scope.statusList[0])) {
            status = "A";
        } else if (this.$scope.haveSameId(this.$scope.filters.status, this.$scope.statusList[1])) {
            status = "V";
        } else if (this.$scope.haveSameId(this.$scope.filters.status, this.$scope.statusList[2])) {
            status = "N";
        }

        // Calcolo le tech e soft skill in base alla scelta nella select
        if (this.$scope.haveSameId(this.$scope.filters.competence, this.$scope.competenceList[0])) {
            techSkillsOnly = false;
            softSkillsOnly = false;
        } else if (this.$scope.haveSameId(this.$scope.filters.competence, this.$scope.competenceList[1])) {
            techSkillsOnly = true;
            softSkillsOnly = false;
        } else if (this.$scope.haveSameId(this.$scope.filters.competence, this.$scope.competenceList[2])) {
            techSkillsOnly = false;
            softSkillsOnly = true;
        }

        let pageNum: number = null;

        // Offeset e ResultLimit sono due parametri passati automaticamente dal componente della tabella. La prima volta, non esisteranno. Le altre sì, e mi serviranno per sapere se sto procedendo o retrocedendo nella ricerca
        if (offset && resultLimit) {
            pageNum = (offset - 1) * resultLimit;
        } else if (this.$scope.filters && this.$scope.filters.tableFilter && this.$scope.filters.tableFilter.page) {
            pageNum = (this.$scope.filters.tableFilter.page - 1) * this.$scope.infiniteScrollLoadCount;
        } else {
            pageNum = this.$scope.filters.infiniteScrollItemsStartOffSet;
        }

        // Tipi di oggetti da recueprare
        let itemTypes: Array<string> = this.$scope.filters.openedTab === 1 ? [this.$scope.bgAcademyApplicationData.constants.CONTAINER] : [
            this.$scope.bgAcademyApplicationData.constants.ITEM,
            //this.$scope.bgAcademyApplicationData.constants.CONTAINER,
            this.$scope.bgAcademyApplicationData.constants.SCORM_FREE,
            this.$scope.bgAcademyApplicationData.constants.SCORM_INVITE,
            this.$scope.bgAcademyApplicationData.constants.DAM_ITEM
        ];

        // Recupero il numero di utenti
        let countItems = this.$scope.countItems(searchedText, status, techSkillsOnly, softSkillsOnly, techCompetences, softCompetences, argumentsTags, objectTypes, itemTypes);

        // Poi, con il recupero vero e proprio degli utenti
        countItems.then(() => {
            // Abilito la ricerca se mi mancano ancora altri utenti da visualizzare
            if (this.$scope.filters.infiniteScrollItemsStartOffSet < this.$scope.filters.totalItemsCount) {
                // Eseguo la ricerca, eliminando le eventuali altre pendenti
                if (this.$scope.getItemListPromise) {
                    this.$scope.getItemListPromise.$cancelRequest();
                }
                this.$scope.getItemListPromise =
                    this.ItemService.searchItems.query({
                        withRegistration: true,
                        sorting: 'D',
                        fromRecord: pageNum,
                        numRecords: this.$scope.infiniteScrollLoadCount,
                        searchedText: searchedText,
                        status: status,
                        techSkillsOnly: techSkillsOnly,
                        softSkillsOnly: softSkillsOnly,
                        techCompetences: techCompetences,
                        softCompetences: softCompetences,
                        argumentsTags: argumentsTags,
                        objectTypes: objectTypes && objectTypes.length ? objectTypes : this.$scope.allObjectTypes,
                        itemTypes: itemTypes,
                        ignoreVisibility: true,
                        withChildsLoaded: this.$scope.filters.openedTab === 1 ? true : false,
                        withAllVisibilitiesLoaded: this.$scope.withAllVisibilitiesLoaded || (this.$scope.filters.openedTab === 1 ? true : false),
                        withGroupsLoaded: this.$scope.filters.openedTab === 1 ? true : false,
                        laodInvitedInstead: this.$scope.filters.openedTab === 1 ? true : false,
                        skipChildEngagements: true,
                        skipChildVisibilitiesLoaded: true
                    });
                this.$scope.getItemListPromise.$promise
                    .then((data: SenecaResponse<Array<User>>) => {
                        if (data.error) {
                            // Dati non validi, quindi alzo l'errore
                            this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                        } else {
                            // Aggiungo gli elementi trovati o rimuovo gli elementi trovati
                            if (this.$scope.filters.infiniteScrollItemsStartOffSet > offset) {
                                this.$scope.addFoundedItems(data.response, data.response.length);
                            } else {
                                this.removeFoundedItems(data.response, data.response.length);
                            }
                            this.$scope.newSearch = false;
                        }
                        // Abilito i pulsanti
                        this.$scope.isDoingSomething = false;
                        // Annullo la promessa
                        this.$scope.getItemListPromise = null;
                        // Disattivo il blockUI
                        this.$scope.genericBlockUI.stop();
                    })
                    .catch((error: any) => {
                        // Abilito i pulsanti
                        this.$scope.isDoingSomething = false;
                        // Disattivo il blockUI
                        this.$scope.genericBlockUI.stop();
                        // Annullo la promessa
                        this.$scope.getItemListPromise = null;
                        // Non mostro la modale di errore se ho cancellato volutamente la richiesta                            
                        if (!error || error.config.timeout.$$state.status !== 1) {
                            let errors: Array<any> = [];
                            errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                            this.$rootScope.$emit("showApplicationModalErrors", errors);
                        }
                    });
            }
            else {
                // Abilito i pulsanti
                this.$scope.isDoingSomething = false;
                // Disattivo il blockUI
                this.$scope.genericBlockUI.stop();
            }
        })
            .catch((error: any) => {
                // Disattivo il blockUI
                this.$scope.genericBlockUI.stop();
                // Annullo la promessa
                this.$scope.countItemListPromise = null;
                // Non mostro la modale di errore se ho cancellato volutamente la richiesta                            
                if (!error || error.config.timeout.$$state.status !== 1) {
                    let errors: Array<any> = [];
                    errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                    this.$rootScope.$emit("showApplicationModalErrors", errors);
                }
            });
        // }
    }

    // Aggiunge gli elementi trovati dopo la ricerca
    public addFoundedItems = (searchResult: Array<any>, loadedElements: number) => {
        // Prima di salvare i dati recuperati, verifico la tipologia dell'oggetto e calcolo il monte ore
        if (searchResult && searchResult.length) {
            for (let i = 0; i < searchResult.length; i++) {
                // Item corrente
                let currentItem = searchResult[i];
                this.ItemService.fillContainerAdditionalData(currentItem, this.$translate, this.$scope.bgAcademyApplicationData, this.moment, this.$sessionStorage.adminMode);
            }
        }
        // Poi appendo i risultati
        this.$scope.itemList = searchResult;
        this.$scope.totalItemsLoaded = this.$scope.itemList.length;
        // Mi segno che sono arrivato a questo punto della lista
        this.$scope.filters.infiniteScrollItemsStartOffSet += loadedElements;
    };

    public removeFoundedItems = (searchResult: Array<any>, loadedElements: number) => {
        // Prima di salvare i dati recuperati, verifico la tipologia dell'oggetto e calcolo il monte ore
        if (searchResult && searchResult.length) {
            for (let i = 0; i < searchResult.length; i++) {
                // Item corrente
                let currentItem = searchResult[i];
                this.ItemService.fillContainerAdditionalData(currentItem, this.$translate, this.$scope.bgAcademyApplicationData, this.moment, this.$sessionStorage.adminMode);
            }
        }
        // Poi appendo i risultati
        this.$scope.itemList = searchResult;
        this.$scope.totalItemsLoaded = this.$scope.itemList.length;
        // Mi segno che sono arrivato a questo punto della lista
        this.$scope.filters.infiniteScrollItemsStartOffSet -= loadedElements;
    };

    // Verifica se due oggetti sono uguali
    public haveSameId = (obj1: any, obj2: any) => {
        if (obj1 && obj2) {
            return obj1.id === obj2.id;
        }
    }

    // Cambia la visualizzazione lista / cards
    public toggleCardResultView = () => {
        this.$scope.isCardResultView = !this.$scope.isCardResultView;
    }

}

angular.module("app").directive("itemListAdmin", () => {
    return {
        templateUrl: "app/shared/itemListAdmin/itemListAdminDirective.html",
        scope: {
            editMode: "<",
            selectedItems: "=?",
            selectionMode: "<",
            allObjectTypes: "<",
            showContainersTab: "<",
            withAllVisibilitiesLoaded: "<"
        },
        controller: ItemListAdminDirectiveController
    };
});